<template>
  <div ref="editor"></div>
  <!-- <button @click='syncHTML'>同步内容</button>
    <div :innerHTML='content.html'></div> -->
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from "vue";
import { uploadUrl, getHeader } from "@/utils/tools";
// 引入wangeditor组件
import WangEditor from "wangeditor";

// 官方文档：https://doc.wangeditor.com/
export default {
  props: {
    editorValue: {
      type: String,
      default: "",
    },
    menus: {
      type: Array,
      default: [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "italic",
        "underline",
        "strikeThrough",
        // 'indent',
        "lineHeight",
        "foreColor",
        "backColor",
        "link",
        "list",
        // 'todo',
        "justify",
        "quote",
        // 'emoticon',
        "image",
        "video",
        "table",
        // 'code',
        "splitLine",
        "undo",
        "redo",
      ],
    },
    heigt: {
      type: Number,
      default: 200,
    },
    placeholder: {
      type: String,
      default: "请输入正文",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  name: "Wangeditor",
  setup(props, content) {
    // 获取编辑器实例html
    const editor = ref();
    // 编辑器实例对象
    let instance = "";
    // instance.txt.clear();
    /**
     * @name: 生命周期函数-----挂载完成
     * @author: camellia
     * @email: guanchao_gc@qq.com
     * @date: 2021-01-19
     */
    onMounted(() => {
      // 编辑器实例对象
      instance = new WangEditor(editor.value);
      // 自定义菜单
      instance.config.menus = props.menus;
      // 代码高亮
      //   instance.highlight = hljs;
      // 关闭样式过滤
      instance.config.pasteFilterStyle = false;
      // 开启本地上传图片(这是后端上传链接)
      instance.config.uploadImgServer = uploadUrl + "lms/upload/editor/file";
      // 限制上传图片格式
      instance.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif", "bmp"];
      instance.config.uploadFileName = "file";
      instance.config.uploadImgMaxLength = 1;
      instance.config.uploadImgHeaders = getHeader();
      instance.config.uploadImgHooks = {
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function (insertImgFn, result) {
          // result 即服务端返回的接口
          // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
          insertImgFn(result.data.url);
        },
      };
      // 开启本地上传视频(这是后端上传链接)
      instance.config.uploadVideoServer = uploadUrl + "lms/upload/editor/file";
      instance.config.uploadVideoHeaders = getHeader();
      instance.config.uploadVideoName = "file";
      instance.config.uploadVideoHooks = {
        // 视频上传并返回了结果，想要自己把视频插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
        customInsert: function (insertVideoFn, result) {
          // result 即服务端返回的接口
          // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
          insertVideoFn(result.data.url);
        },
      };
      // 设置编辑器高度
      instance.config.height = props.heigt;
      // 设置编辑器页面层级
      instance.config.zIndex = 2;
      // 设置编辑器placeholder
      instance.config.placeholder = props.placeholder;
      // 配置编辑器显示颜色
      //   instance.config.colors = ['#000000', '#eeece0', '#1c487f', '#4d80bf'];
      // 忽略粘贴内容中的图片
      instance.config.pasteIgnoreImg = true;
      instance.config.focus = false;
      // instance.config.readOnly = props.disabled;

      Object.assign(instance.config, {
        // wangeditor 值发生变化的时候
        onchange() {
          // 将值传递至父组件
          content.emit("getWangEditorValue", instance.txt.html());
          content.emit("getWangEditorText", instance.txt.text());
        },
        // 上传网络图片回调
        linkImgCallback(src) {
          console.log("图片 src ", src);
        },
        // 上传网络视频回调
        onlineVideoCallback(video) {
          // 自定义回调内容，内容成功插入后会执行该函数
          console.log("插入视频内容", video);
        },
      });
      instance.create();
      instance.txt.html(props.editorValue);
      props.disabled && instance.disable();
    });

    const clear = () => {
      if (instance) instance.txt.clear();
    };
    /**
     * @name: 生命周期函数-----页面卸载之前
     * @author: camellia
     * @email: guanchao_gc@qq.com
     * @date: 2021-01-19
     */
    onBeforeUnmount(() => {
      instance.destroy();
      instance = null;
    });
    return {
      editor,
      clear,
    };
  },
};
</script>
